@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

body::-webkit-scrollbar {
  width: 15px; /* Adjust width as needed */
}

body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background */
}

body::-webkit-scrollbar-thumb {
  background: #ccc; /* Light gray thumb */
  border-radius: 10px; /* Capsule shape */
}
.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #d9d9d9 !important; /* Change to your desired border color */
  box-shadow: none !important;
}
.ant-modal-content {
  padding: 6.5px !important;
  width: 100% !important;
}
.custom-spin {
  color: orange !important; /* Change color as needed */
}
.ant-spin-dot-item {
  background-color: orange !important;
}
.ant-modal-content {
  border-radius: 30px !important;
}
