/* animations.css */
@keyframes fadeInSlow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-slow {
  animation: fadeInSlow 1s ease-in-out forwards;
}
